import React from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import ColumnVolumeEditor from 'client/app/components/Parameters/ChromatographyActions/ColumnVolumeEditor';
import {
  formatRoboColumnVolumeUnknownError,
  getRoboColumnVolume,
} from 'client/app/components/Parameters/ChromatographyActions/roboColumnVolumeUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import {
  fractionVolumeToTotalMass,
  fractionVolumeToTotalVolume,
  fromColumnVolumes,
  totalMassToFractionVolume,
  totalVolumeToFractionVolume,
} from 'common/lib/chromatography';
import { parseMeasurement, roundMeasurement } from 'common/lib/format';
import { RoboColumnContent } from 'common/types/robocolumns';
import IntegerEditor from 'common/ui/components/ParameterEditors/IntegerEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';

type FractionInputsProps = {
  isDisabled?: boolean;
  robocolumns: RoboColumnContent[];
  fractionCount?: number;
  setFractionCount: (newFractionCount?: number) => void;
  fractionVolume?: string;
  setFractionVolume: (newResidenceTime?: string) => void;
  /**
   * Required to calculate mass
   */
  liquidConcentration?: string;
};

/**
 * Inputs for setting fraction count and volume for a given chromatography run. The user
 * can input any two of fraction count, fraction volume, or total volume (the volume to
 * load for each fraction multiplied by the number of fractions). The third input will be
 * automatically computed.
 *
 * By default, fraction volume is in CV (column volume, ie units of the volume of
 * robocolumn) so the user can understand the volume relative to the size of the
 * robocolumn. They can alternatively enter fraction volume in ul.
 *
 * Total volume is in ul by default so that the user can understand the total volume
 * relative to their stock of load liquid. They can alternatively enter total volume in
 * CV.
 */
export function FractionInputs({
  isDisabled,
  robocolumns,
  fractionCount,
  fractionVolume,
  liquidConcentration,
  setFractionCount,
  setFractionVolume,
}: FractionInputsProps) {
  const robocolumnVolume = getRoboColumnVolume(robocolumns);

  const { totalVolume, setTotalVolume, totalMass, setTotalMass } = useFractionConversions(
    {
      fractionCount,
      fractionVolume,
      robocolumnVolume,
      liquidConcentration,
      onFractionVolumeChange: setFractionVolume,
    },
  );

  const fractionVolumeRounded = fractionVolume && roundMeasurement(fractionVolume, 2); // Should allow 2dp, e.g. 0.25CV
  const totalVolumeRounded = totalVolume && roundMeasurement(totalVolume, 1);
  const totalMassRounded = totalMass && roundMeasurement(totalMass, 1);

  const showLoadMassInput = useFeatureToggle('LOAD_ROBOCOLUMN_BY_MASS');

  return (
    <>
      <Typography variant="subtitle2">Load Calculations</Typography>
      <Box>
        <InputLabel shrink>Number of Fractions</InputLabel>
        <IntegerEditor
          type=""
          value={fractionCount}
          onChange={setFractionCount}
          isDisabled={isDisabled}
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>Load Volume Per Fraction</InputLabel>
        <ColumnVolumeEditor
          value={fractionVolumeRounded}
          roboColumnVolume={robocolumnVolume}
          onChange={setFractionVolume}
          isDisabled={isDisabled}
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>Total Load Volume</InputLabel>
        <ColumnVolumeEditor
          value={totalVolumeRounded}
          roboColumnVolume={robocolumnVolume}
          onChange={setTotalVolume}
          isDisabled={isDisabled || !robocolumnVolume}
          isRequired
        />
        {!robocolumnVolume && (
          <FormHelperText disabled>
            {formatRoboColumnVolumeUnknownError('total load volume', robocolumns)}
          </FormHelperText>
        )}
      </Box>
      {showLoadMassInput && (
        <Box>
          <InputLabel shrink>Load Mass</InputLabel>
          <MeasurementEditor
            units={['mg']}
            defaultUnit="mg"
            value={totalMassRounded}
            onChange={setTotalMass}
            isDisabled={isDisabled || !liquidConcentration}
            isRequired
          />
          {!liquidConcentration && (
            <FormHelperText disabled>
              No concentration found for this liquid
            </FormHelperText>
          )}
        </Box>
      )}
    </>
  );
}

function useFractionConversions({
  fractionCount,
  fractionVolume,
  liquidConcentration,
  robocolumnVolume,
  onFractionVolumeChange,
}: {
  fractionCount?: number;
  fractionVolume?: string;
  liquidConcentration?: string;
  robocolumnVolume?: string;
  onFractionVolumeChange: (newFractionVolume?: string) => void;
}): {
  totalVolume?: string;
  setTotalVolume: (volume?: string) => void;
  totalMass?: string;
  setTotalMass: (mass?: string) => void;
} {
  const totalVolume =
    fractionVolume && fractionCount && robocolumnVolume
      ? fractionVolumeToTotalVolume(fractionVolume, fractionCount, robocolumnVolume)
      : undefined;

  const setTotalVolume = (newTotalVolume?: string) => {
    // Compute the new volume per fraction. This will return a volume in CV.
    const newFractionVolumeInCV =
      newTotalVolume && fractionCount && robocolumnVolume
        ? totalVolumeToFractionVolume(newTotalVolume, fractionCount, robocolumnVolume)
        : undefined;
    const currFractionVolumeUnit = parseMeasurement(fractionVolume || '')?.unit;

    if (currFractionVolumeUnit !== 'CV') {
      onFractionVolumeChange(
        newFractionVolumeInCV && robocolumnVolume
          ? fromColumnVolumes(newFractionVolumeInCV, robocolumnVolume)
          : undefined,
      );
    } else {
      onFractionVolumeChange(newFractionVolumeInCV);
    }
  };

  const totalMass =
    fractionVolume && fractionCount && robocolumnVolume && liquidConcentration
      ? fractionVolumeToTotalMass(
          fractionVolume,
          fractionCount,
          robocolumnVolume,
          liquidConcentration,
        )
      : undefined;

  const setTotalMass = (newMass?: string) => {
    if (!newMass || !fractionCount || !liquidConcentration) {
      return;
    }
    onFractionVolumeChange(
      totalMassToFractionVolume(newMass, fractionCount, liquidConcentration),
    );
  };

  return { totalVolume, setTotalVolume, totalMass, setTotalMass };
}
