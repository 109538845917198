import React, { useCallback, useEffect, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  Step,
  WorkflowSchemaInput,
  WorkflowSchemaOutputs,
} from 'client/app/apps/protocols/useGetData';
import { InstanceParameter } from 'client/app/components/Parameters/ElementParameterGroupList';
import { useFilterPlateContentParameters } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ElementInstance, Parameter, ParameterValue } from 'common/types/bundle';
import Colors from 'common/ui/Colors';

type Props = {
  step: Step;
  isOpen: boolean;
  onClick: () => void;
  inputs: WorkflowSchemaInput[];
  outputs: WorkflowSchemaOutputs[];
};

export const InputStep = ({ step, isOpen, onClick, inputs, outputs }: Props) => {
  const dispatch = useWorkflowBuilderDispatch();
  const {
    elementInstances,
    parameters,
    config: workflowConfig,
  } = useWorkflowBuilderSelector(state => state);

  const handleSetOutputPreviewPanel = useCallback(() => {
    const [elementInstanceName, parameter] = outputs[0].path;
    const elementInstance = elementInstances.find(ei => ei.name === elementInstanceName);

    dispatch({
      type: 'openOutputPreviewPanel',
      payload: {
        selectedElementId: elementInstance?.Id,
        selectedOutputParameterName: parameter,
        outputType: 'Liquids', // TODO: Refactor to use getOutputVisualisationType
        entityView: 'plate', // TODO: consider encoding some state in URL-params
      },
    });
  }, [dispatch, elementInstances, outputs]);

  useEffect(() => {
    if (isOpen && outputs.length > 0) {
      handleSetOutputPreviewPanel();
    }
  }, [handleSetOutputPreviewPanel, isOpen, outputs.length]);

  // This value is a map of each protocol input id to the parent element
  // that the input refers to.
  // This is used to pass down to render ParameterEditor component.
  const protocolElementsByInputId = useMemo(() => {
    const ret = new Map<string, ElementInstance>();
    inputs.forEach(input => {
      const element = elementInstances.find(ei => ei.Id === input.elementId);
      if (element) {
        ret.set(input.id, element);
      }
    });
    return ret;
  }, [elementInstances, inputs]);

  // This value is a map of each protocol input id to a Parameter
  // type. The Parameter is constructed using information from the
  // workflowSchema, such as the display description.
  // This is used to pass down to render to ParameterEditor component.
  const protocolParametersByInputId = useMemo(() => {
    const ret = new Map<string, Parameter>();
    inputs.forEach(input => {
      const parameterName = input.path[1];
      const param: Parameter = {
        name: parameterName,
        description: input.configuration.displayDescription, // TODO - Is this the right display description?
        type: input.typeName,
        configuration: input.configuration,
      };
      ret.set(input.id, param);
    });
    return ret;
  }, [inputs]);

  // We filter out some parameters that are managed by the PlateContentsEditor component.
  const { plateContentParams, plateParameterFilter } = useFilterPlateContentParameters([
    ...protocolParametersByInputId.values(),
  ]);

  const handleParameterChange = useCallback(
    (paramName: string, value: ParameterValue, instanceName?: string) => {
      dispatch({
        type: 'updateParameter',
        payload: {
          instanceName: instanceName ?? '',
          parameterName: paramName,
          value: value,
        },
      });
    },
    [dispatch],
  );

  const handleOnClick = () => {
    onClick();
    if (!isOpen) {
      if (outputs.length > 0) {
        handleSetOutputPreviewPanel();
      } else {
        dispatch({ type: 'closeOutputPreviewPanel' });
      }
    }
  };

  return (
    <Wrapper isOpen={isOpen} onClick={handleOnClick}>
      <Typography variant="h4">{step.name}</Typography>
      {isOpen &&
        step.inputIds
          .filter(inputId =>
            plateParameterFilter(protocolParametersByInputId.get(inputId)),
          )
          .map(inputId => {
            const element = protocolElementsByInputId.get(inputId);
            const parameter = protocolParametersByInputId.get(inputId);
            return element && parameter ? (
              <InstanceParameter
                key={inputId}
                instanceName={element.name}
                elementId={element.Id}
                paramState={undefined}
                onChange={handleParameterChange}
                parameter={parameter}
                workflowConfig={workflowConfig}
                defaultParameters={{}}
                parameterValueDict={parameters[element.name]}
                paramValue={parameters[element.name][parameter.name]}
                plateContentParams={plateContentParams}
              />
            ) : null;
          })}
    </Wrapper>
  );
};

const Wrapper = styled('div')<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '376px',
  padding: theme.spacing(5),
  gap: theme.spacing(5),
  borderRadius: theme.spacing(3),
  border: `1px solid ${isOpen ? Colors.PRIMARY_MAIN : Colors.GREY_20}`,
  backgroundColor: 'white',
  cursor: 'pointer',
}));
