import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';

import { EditProtocolInstanceHeader } from 'client/app/apps/protocols/EditProtocolInstanceHeader';
import { InputSteps } from 'client/app/apps/protocols/InputSteps';
import { useComplexParameterEditorDialogManager } from 'client/app/apps/protocols/lib/utils';
import { Step, WorkflowSchema } from 'client/app/apps/protocols/useGetData';
import { OutputPreviewPanel } from 'client/app/apps/workflow-builder/output-preview/OutputPreviewPanel';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { ParameterValue } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
type Props = {
  steps: Step[];
  workflowSchema: WorkflowSchema;
};

export const EditProtocolInstance = ({ steps, workflowSchema }: Props) => {
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const dialogs = useComplexParameterEditorDialogManager();

  const handleOnSimulate = useCallback(() => {
    const updatedProtocolParams: { [inputId: string]: ParameterValue } = {};
    workflowSchema.inputs.forEach(input => {
      // TODO - Change this to reflect new workflow schema.
      // Currently we mock up the structure of path to be:
      // [ElementInstanceName, ParameterName]
      // but this will certainly change on implementation.
      const [elementInstanceName, parameterName] = input.path;
      const paramValue = parameters[elementInstanceName][parameterName];

      updatedProtocolParams[input.id] = paramValue;
    });
    // TODO - Submit callback with data, right now we just log it out
    // for dev validation purposes.
    console.log(updatedProtocolParams);
  }, [parameters, workflowSchema.inputs]);

  return (
    <Wrapper>
      <EditProtocolInstanceHeader handleOnSimulate={handleOnSimulate} />
      <InputOutputSection>
        <InputSteps steps={steps} workflowSchema={workflowSchema} />
        <OutputPreviewPanel onClose={() => {}} className="" />
      </InputOutputSection>
      {dialogs}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(7),
  gap: theme.spacing(6),
  backgroundColor: Colors.GREY_5,
}));

const InputOutputSection = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(6),
}));
