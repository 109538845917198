import React, { useEffect, useMemo } from 'react';

import ArrowRightSharpIcon from '@mui/icons-material/ArrowRightSharp';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Alert from '@mui/material/Alert';
import Grow from '@mui/material/Grow';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import OutputDataTable from 'client/app/apps/workflow-builder/output-preview/OutputDataTable';
import OutputPreviewTable from 'client/app/apps/workflow-builder/output-preview/OutputPreviewTable';
import { useElementParameterAndColorsForOutputs } from 'client/app/apps/workflow-builder/output-preview/outputPreviewUtils';
import usePlateSelection from 'client/app/components/ElementPlumber/ElementOutputs/hooks/usePlateSelection';
import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import {
  useElementParameterOutput,
  useOutputFilterMatrixesByPlate,
  useOutputLiquidsByPlate,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { FilterMatrix, Liquid } from 'common/types/bundle';
import { DataTable } from 'common/types/spreadsheetEditor';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import PlateLayout from 'common/ui/components/PlateLayout/PlateLayout';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import {
  DeckItemWithWellsGeometry,
  getLayoutForWellSelector,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import Dropdown from 'common/ui/filaments/Dropdown';
import { PlateIcon } from 'common/ui/icons/Plate';

type OutputPreviewPanelProps = {
  onClose: () => void;
  className: string;
};

export function OutputPreviewPanel(props: OutputPreviewPanelProps) {
  const { className, onClose } = props;
  const dispatch = useWorkflowBuilderDispatch();

  const { instanceId, selectedOutputParameterName, outputType } =
    useWorkflowBuilderSelector(state => state.outputPreviewPanelProps);

  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const elementInstance = elementInstances.find(ei => ei.Id === instanceId);

  if (!elementInstance || !selectedOutputParameterName) {
    return null;
  }

  if (
    outputType !== 'FilterMatrix' &&
    outputType !== 'Liquids' &&
    outputType !== 'DataTable'
  ) {
    return null;
  }

  const PanelComponent =
    outputType === 'Liquids'
      ? LiquidsOutputPreviewPanel
      : outputType === 'FilterMatrix'
      ? FilterMatrixesOutputPreviewPanel
      : outputType === 'DataTable'
      ? DataTableOutputPreviewPanel
      : null;

  if (PanelComponent === null) {
    return null;
  }

  const handleCloseOutputPreviewPanel = () => {
    onClose();
    dispatch({ type: 'closeOutputPreviewPanel' });
  };

  return (
    <PanelComponent
      elementId={elementInstance.Id}
      parameterName={selectedOutputParameterName}
      onClose={handleCloseOutputPreviewPanel}
      className={className}
    />
  );
}

type OutputPreviewPanelContainerProps = {
  className: string;
  elementInstanceName: string;
  parameterDisplayName: string;
  onClose: () => void;
};

/**
 * The main wrapper panel component for rendering out the output preview panel.
 * Content to be rendered should be passed in as children.
 */
function OutputPreviewPanelContainer(
  props: React.PropsWithChildren<OutputPreviewPanelContainerProps>,
) {
  const { className, elementInstanceName, parameterDisplayName, onClose, children } =
    props;
  return (
    <Grow in unmountOnExit>
      <Panel elevation={4} className={className}>
        <TitleBar>
          <ElementParameterBreadcrumb>
            <RemoveRedEyeIcon />
            <TypographyWithTooltip variant="subtitle2">
              {elementInstanceName}
            </TypographyWithTooltip>
            <StyledArrowRightSharpIcon />
            <TypographyWithTooltip variant="subtitle2">
              {parameterDisplayName}
            </TypographyWithTooltip>
          </ElementParameterBreadcrumb>
          <IconButton
            icon={<CloseOutlined />}
            size="xsmall"
            onClick={onClose}
            color="inherit"
          />
        </TitleBar>
        <ContentContainer>{children}</ContentContainer>
      </Panel>
    </Grow>
  );
}

type LiquidsFilterMatrixOutputPreviewPanelProps = OutputPreviewPanelProps & {
  elementId: string;
  parameterName: string;
};

export function LiquidsOutputPreviewPanel(
  props: LiquidsFilterMatrixOutputPreviewPanelProps,
) {
  const { elementId, parameterName, onClose, className } = props;
  const { elementInstanceName, parameterDisplayName, liquidColors } =
    useElementParameterAndColorsForOutputs(elementId, parameterName);
  const [loading, plateStateMap, outputLiquids] = useOutputLiquidsByPlate(
    elementId,
    liquidColors,
    parameterName,
  );
  return (
    <LiquidsAndFilterMatrixPreviewPanelContent
      onClose={onClose}
      className={className}
      elementInstanceName={elementInstanceName}
      parameterDisplayName={parameterDisplayName}
      liquidColors={liquidColors}
      plateStateMap={plateStateMap}
      outputs={outputLiquids}
      loading={loading}
    />
  );
}

export function FilterMatrixesOutputPreviewPanel(
  props: LiquidsFilterMatrixOutputPreviewPanelProps,
) {
  const { elementId, parameterName, onClose, className } = props;
  const { elementInstanceName, parameterDisplayName, liquidColors } =
    useElementParameterAndColorsForOutputs(elementId, parameterName);
  const [loading, plateStateMap, outputFilterMatrixes] = useOutputFilterMatrixesByPlate(
    elementId,
    liquidColors,
    parameterName,
  );
  return (
    <LiquidsAndFilterMatrixPreviewPanelContent
      onClose={onClose}
      className={className}
      elementInstanceName={elementInstanceName}
      parameterDisplayName={parameterDisplayName}
      liquidColors={liquidColors}
      plateStateMap={plateStateMap}
      outputs={outputFilterMatrixes}
      loading={loading}
    />
  );
}

type DataTableOutputPreviewPanelProps = OutputPreviewPanelProps & {
  elementId: string;
  parameterName: string;
};

export function DataTableOutputPreviewPanel(props: DataTableOutputPreviewPanelProps) {
  const { elementId, parameterName, onClose, className } = props;
  const { elementInstanceName, parameterDisplayName } =
    useElementParameterAndColorsForOutputs(elementId, parameterName);
  const [loading, outputDataTable, _] = useElementParameterOutput<DataTable>(
    elementId,
    parameterName,
  );

  const entityView = useWorkflowBuilderSelector(
    state => state.outputPreviewPanelProps?.entityView,
  );

  return (
    <OutputPreviewPanelContainer
      onClose={onClose}
      className={className}
      elementInstanceName={elementInstanceName}
      parameterDisplayName={parameterDisplayName}
    >
      <DataTableOutputPreviewContent
        outputs={outputDataTable}
        loading={loading}
        entityView={entityView}
      />
    </OutputPreviewPanelContainer>
  );
}

type DataTableOutputPreviewContentProps = {
  outputs: DataTable | undefined;
  loading: boolean;
  entityView: OutputEntity | undefined;
};

function DataTableOutputPreviewContent(props: DataTableOutputPreviewContentProps) {
  const { outputs, loading, entityView = 'rows' } = props;
  if (loading) {
    return <LinearProgress />;
  }

  if (!outputs) {
    return (
      <StyledError severity="error">
        Output data table information could not be found
      </StyledError>
    );
  }

  return <OutputDataTable table={outputs} entityView={entityView} />;
}

type LiquidsAndFilterMatrixesOutputPreviewPanelContentProps = Pick<
  LiquidsFilterMatrixOutputPreviewPanelProps,
  'onClose' | 'className'
> & {
  elementInstanceName: string;
  parameterDisplayName: string;
  liquidColors: LiquidColors;
  plateStateMap: Map<string, PlateState>;
  outputs: (Liquid | FilterMatrix)[] | undefined;
  loading: boolean;
};

/**
 * Preview Panel to be used for displaying the liquid-type outputs of elements within the Builder.
 * Will render a plate preview of the outputs as well as a table view of the outputs.
 */
function LiquidsAndFilterMatrixPreviewPanelContent(
  props: LiquidsAndFilterMatrixesOutputPreviewPanelContentProps,
) {
  const {
    onClose,
    className,
    liquidColors,
    plateStateMap,
    elementInstanceName,
    parameterDisplayName,
    outputs,
    loading,
  } = props;

  const dispatch = useWorkflowBuilderDispatch();
  const entityView = useWorkflowBuilderSelector(
    state => state.outputPreviewPanelProps?.entityView,
  );

  const handleSelectView = (entityView: OutputEntity | undefined) => {
    dispatch({ type: 'setOutputPreviewPanelEntityView', payload: entityView });
  };

  const selectedPlateName = useWorkflowBuilderSelector(
    state => state.outputPreviewPanelProps.selectedPlateName,
  );

  const plateState = selectedPlateName
    ? plateStateMap.get(selectedPlateName)
    : (plateStateMap.values().next().value as PlateState | undefined);

  const plateOptions = [...plateStateMap.keys()].map(name => ({
    label: name,
    value: name,
  }));

  const selectPlate = usePlateSelection();

  // Selects plateName or first available plate when output is changed.
  // If no plate is found, the plateName will be set to undefined.
  useEffect(() => {
    if (!selectedPlateName || !plateStateMap.has(selectedPlateName)) {
      const firstAvailablePlateName = plateStateMap.keys().next().value;
      selectPlate(firstAvailablePlateName);
    }
  }, [dispatch, plateStateMap, selectPlate, selectedPlateName]);

  const geometry = useMemo(() => {
    if (!loading && plateState) {
      const deckLayout = getLayoutForWellSelector(plateState);
      return deckLayout.getCurrentGeometry(plateState);
    }
    return undefined;
  }, [loading, plateState]);

  const hasOutputsButNoPlates = outputs && !plateState;

  return (
    <OutputPreviewPanelContainer
      className={className}
      elementInstanceName={elementInstanceName}
      parameterDisplayName={parameterDisplayName}
      onClose={onClose}
    >
      {!hasOutputsButNoPlates && (
        <Header>
          <>
            <PlateSelectDropdown
              placeholder="Plate name"
              valueLabel={selectedPlateName ?? ''}
              options={plateOptions}
              onChange={selectPlate}
              isRequired
              renderValue={value => {
                return (
                  <DropdownValue>
                    <PlateIcon />
                    {value}
                  </DropdownValue>
                );
              }}
              isDisabled={plateOptions.length <= 1}
            />
            <ViewToggle
              value={entityView}
              onChange={(_, value) => handleSelectView(value)}
              exclusive
            >
              <ToggleButton value="plate">Preview</ToggleButton>
              <ToggleButton value="liquid">Table</ToggleButton>
            </ViewToggle>
          </>
        </Header>
      )}
      <LiquidAndFiltermatrixesOutputPreviewContent
        geometry={geometry}
        plateState={plateState}
        liquidColors={liquidColors}
        outputs={outputs}
        loading={loading}
        entityView={entityView}
        plateName={selectedPlateName}
      />
      <StyledAlert severity="warning">
        <Typography variant="subtitle2">
          Plate information is partial only.&nbsp;
        </Typography>
        <Typography>
          Only liquids and wells processed by this element are available.
        </Typography>
      </StyledAlert>
    </OutputPreviewPanelContainer>
  );
}

type LiquidAndFiltermatrixesOutputPreviewContentProps = {
  geometry: DeckItemWithWellsGeometry | undefined;
  plateState: PlateState | undefined;
  liquidColors: LiquidColors;
  outputs: (Liquid | FilterMatrix)[] | undefined;
  loading: boolean;
  entityView: OutputEntity | undefined;
  plateName: string | undefined;
};

function LiquidAndFiltermatrixesOutputPreviewContent(
  props: LiquidAndFiltermatrixesOutputPreviewContentProps,
) {
  const { geometry, plateState, liquidColors, outputs, loading, entityView, plateName } =
    props;
  if (loading) {
    return <LinearProgress />;
  }

  if (!outputs) {
    return (
      <StyledError severity="error">
        Output liquids information could not be found
      </StyledError>
    );
  }

  if (entityView === 'liquid') {
    return <StyledOutputPreviewTable outputLiquids={outputs} plateName={plateName} />;
  }

  if (!geometry || !plateState) {
    return (
      <StyledError severity="error">
        Plate information could not be found
        {plateName ? ` for plate: ${plateName}.` : '.'}
      </StyledError>
    );
  }
  const dimensions = geometry.getDimensions();

  return (
    <SvgContainer viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}>
      <PlateLayout
        geometry={geometry}
        plate={plateState}
        liquidColors={liquidColors}
        showContentLabels={false}
        showEmptyWellsAsPossiblyAllocated
      />
    </SvgContainer>
  );
}

const Panel = styled(Paper)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
});

const TitleBar = styled('div')(({ theme }) => ({
  gridArea: 'titlebar',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(4),
  maxHeight: '42px',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '& .MuiSvgIcon-root': {
    height: '16px',
    width: '16px',
  },
}));

const ElementParameterBreadcrumb = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

const StyledArrowRightSharpIcon = styled(ArrowRightSharpIcon)({
  color: Colors.GREY_50,
});

const Header = styled('div')(({ theme }) => ({
  gridArea: 'header',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(6),
}));

const ViewToggle = styled(Toggle)({
  width: '250px',
});

const DropdownValue = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const PlateSelectDropdown = styled(Dropdown<string>)(({ theme }) => ({
  maxWidth: '240px',
  '& > .MuiSelect-select': { padding: theme.spacing(2, 3) },
}));

const ContentContainer = styled('div')(({ theme }) => ({
  gridArea: 'main',
  padding: theme.spacing(6),
  height: '100%',
  display: 'grid',
  gridTemplate: `
  'header' auto
  'content' minmax(0, 1fr)
  'alert' auto
`,
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  gridArea: 'alert',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(6),
  '& > .MuiAlert-message': {
    display: 'flex',
  },
}));

const StyledError = styled(Alert)({
  height: 'fit-content',
});

const SvgContainer = styled('svg')({
  // Make sure the whole plate is visible on screen
  height: '100%',
  width: '100%',
});

const StyledOutputPreviewTable = styled(OutputPreviewTable)({
  gridArea: 'main',
});
