import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import isUndefined from 'lodash/isUndefined';

import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import { FractionInputs } from 'client/app/components/Parameters/ChromatographyActions/FractionInputs';
import { ResidenceTimeInputs } from 'client/app/components/Parameters/ChromatographyActions/ResidenceTimeInputs';
import { getRoboColumnVolume } from 'client/app/components/Parameters/ChromatographyActions/roboColumnVolumeUtils';
import { WellParametersProps } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import PolicyParameter from 'client/app/components/Parameters/Policy/PolicyParameter';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { toColumnVolumes } from 'common/lib/chromatography';
import { isDefined } from 'common/lib/data';
import { parseMeasurement } from 'common/lib/format';
import { getFirstValue, mapValues } from 'common/object';
import { BasicChromatographyAction, RoboColumnContent } from 'common/types/robocolumns';
import { GradientChromatographyAction } from 'common/types/robocolumns';
import FloatEditor from 'common/ui/components/ParameterEditors/FloatEditor';

/**
 * Antha type for Liquid Name, used for looking up suggested values in the
 * resin autocomplete input.
 */
const LIQUID_TYPE = 'github.com/Synthace/antha/stdlib/schemas/aliases.LiquidName';

type Props = {
  /**
   * RoboColumns that the user previously defined on the plate.
   */
  robocolumnsByWell?: Map<string, RoboColumnContent>;
  isGradient?: boolean;
} & WellParametersProps<BasicChromatographyAction>;

/**
 * Fields for configuring a group of identical chromatography actions within the
 * Chromatography Actions or Gradient Chromatography Actions dialog.
 */
export default function ChromatographyActionParameters({
  onChange,
  contentsByWell,
  robocolumnsByWell,
  isGradient,
  isDisabled,
}: Props) {
  // Contents of each well within the group will be identical, so we can
  // arbitrarily pick one.
  const contentsOfFirstWell = useMemo(
    () => getFirstValue(contentsByWell),
    [contentsByWell],
  );

  const [fractionCount, setFractionCount] = useState<number | undefined>(
    contentsOfFirstWell?.LoadVolumes?.length,
  );
  // The volume of liquid to load for each fraction
  const [fractionVolume, setFractionVolume] = useState<string | undefined>(
    contentsOfFirstWell?.LoadVolumes?.[0]
      ? contentsOfFirstWell?.LoadVolumes?.[0] + 'CV'
      : undefined,
  );
  const [residenceTime, setResidenceTime] = useState<string | undefined>(
    contentsOfFirstWell?.ResidenceTime,
  );
  const [aspLiquidPolicy, setAspLiquidPolicy] = useState<string | undefined>(
    contentsOfFirstWell?.AspirationLiquidPolicyOverride,
  );

  const robocolumns = useMemo<RoboColumnContent[]>(
    () =>
      [...contentsByWell.keys()]
        .map(wellLocation => robocolumnsByWell?.get(wellLocation))
        .filter(isDefined),
    [contentsByWell, robocolumnsByWell],
  );

  const robocolumnVolume = getRoboColumnVolume(robocolumns);

  const liquidConcentration = useLiquidConcentration(contentsOfFirstWell?.LiquidToLoad);

  const [liquidParams, setLiquidParams] = useState<BasicChromatographyAction | undefined>(
    contentsOfFirstWell,
  );
  const [isLiquidParamsValid, setIsLiquidParamsValid] = useState<boolean>(true);

  // When the states change, trigger onChange with new well contents
  useEffect(() => {
    const isValid =
      isLiquidParamsValid &&
      ![fractionCount, residenceTime, fractionVolume].some(isUndefined);
    const fractionVolumeUnit = parseMeasurement(fractionVolume || '')?.unit;
    // If user specified fraction volume in ul, then convert to CV
    let fractionVolumeInCV: string | undefined;
    if (fractionVolumeUnit === 'CV') {
      fractionVolumeInCV = fractionVolume;
    } else if (fractionVolume && robocolumnVolume) {
      fractionVolumeInCV = toColumnVolumes(fractionVolume, robocolumnVolume);
    }
    const fractionVolumeAmount = parseMeasurement(fractionVolumeInCV || '');
    const newContentsByWell = mapValues(contentsByWell, () => ({
      ...liquidParams,
      LoadVolumes: new Array(fractionCount || 0).fill(fractionVolumeAmount?.value),
      ResidenceTime: residenceTime,
      AspirationLiquidPolicyOverride: aspLiquidPolicy,
    }));
    onChange(newContentsByWell, isValid);
  }, [
    aspLiquidPolicy,
    contentsByWell,
    fractionCount,
    fractionVolume,
    isLiquidParamsValid,
    liquidParams,
    onChange,
    residenceTime,
    robocolumnVolume,
  ]);

  const handleLiquidParamChange = useCallback(
    (action: BasicChromatographyAction, isValid: boolean) => {
      setLiquidParams(action);
      setIsLiquidParamsValid(isValid);
    },
    [],
  );

  const LiquidParametersComponent = isGradient
    ? GradientChromatographyLiquidParameters
    : ChromatographyLiquidParameters;

  return (
    <Stack spacing={3}>
      <LiquidParametersComponent
        action={contentsOfFirstWell}
        onChange={handleLiquidParamChange}
      />
      <StyledDivider />
      <ResidenceTimeInputs
        isDisabled={isDisabled}
        robocolumns={robocolumns}
        residenceTime={residenceTime}
        setResidenceTime={setResidenceTime}
      />
      <StyledDivider />
      <FractionInputs
        isDisabled={isDisabled}
        robocolumns={robocolumns}
        fractionCount={fractionCount}
        fractionVolume={fractionVolume}
        liquidConcentration={liquidConcentration}
        setFractionCount={setFractionCount}
        setFractionVolume={setFractionVolume}
      />
      <StyledDivider />
      <Typography variant="subtitle2">Optional Parameters</Typography>
      <Box>
        <InputLabel shrink>Aspiration Liquid Policy Override</InputLabel>
        <PolicyParameter value={aspLiquidPolicy || ''} onChange={setAspLiquidPolicy} />
      </Box>
    </Stack>
  );
}

type ChromatographyLiquidParametersProps = {
  action?: BasicChromatographyAction;
  onChange: (action: BasicChromatographyAction, isValid: boolean) => void;
};

/**
 * Parameters specific to defining the liquid to load in a chromatography
 * action. Used by ChromatographyActionParameters.
 */
function ChromatographyLiquidParameters({
  action,
  onChange,
}: ChromatographyLiquidParametersProps) {
  const handleChange = useCallback(
    (liquidToLoad?: string) =>
      onChange({ ...action, LiquidToLoad: liquidToLoad }, liquidToLoad !== undefined),
    [action, onChange],
  );
  return (
    <>
      <Typography variant="subtitle2">Liquid to Load</Typography>
      <Box>
        <AutocompleteWithParameterValues
          valueLabel={action?.LiquidToLoad}
          onChange={handleChange}
          anthaType={LIQUID_TYPE}
          acceptCustomValues
          isRequired
        />
      </Box>
    </>
  );
}

type GradientChromatographyLiquidParametersProps = {
  action?: GradientChromatographyAction;
  onChange: (action: GradientChromatographyAction, isValid: boolean) => void;
};

/**
 * Parameters specific to defining the liquid to load in a gradient
 * chromatography action.
 */
function GradientChromatographyLiquidParameters({
  action,
  onChange,
}: GradientChromatographyLiquidParametersProps) {
  const handleChange = useCallback(
    (newAction: GradientChromatographyAction) => {
      const isValid = ![
        action?.BufferA,
        action?.BufferB,
        action?.BStartPercentage,
        action?.BEndPercentage,
      ].some(isUndefined);
      onChange(newAction, isValid);
    },
    [action, onChange],
  );
  const handleBufferAChange = useCallback(
    (bufferA?: string) => handleChange({ ...action, BufferA: bufferA }),
    [handleChange, action],
  );
  const handleBufferBChange = useCallback(
    (bufferB?: string) => handleChange({ ...action, BufferB: bufferB }),
    [handleChange, action],
  );
  const handleStartPercentChange = useCallback(
    (startPercent?: number | null) =>
      handleChange({ ...action, BStartPercentage: startPercent ?? undefined }),
    [handleChange, action],
  );
  const handleEndPercentChange = useCallback(
    (endPercent?: number | null) =>
      handleChange({ ...action, BEndPercentage: endPercent ?? undefined }),
    [handleChange, action],
  );

  return (
    <>
      <Typography variant="subtitle2">Gradient Preparation</Typography>
      <Box>
        <InputLabel shrink>Buffer A</InputLabel>
        <AutocompleteWithParameterValues
          valueLabel={action?.BufferA}
          onChange={handleBufferAChange}
          anthaType={LIQUID_TYPE}
          acceptCustomValues
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>Buffer B</InputLabel>
        <AutocompleteWithParameterValues
          valueLabel={action?.BufferB}
          onChange={handleBufferBChange}
          anthaType={LIQUID_TYPE}
          acceptCustomValues
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>Start Percentage of Buffer B</InputLabel>
        <FloatEditor
          type=""
          value={action?.BStartPercentage}
          onChange={handleStartPercentChange}
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>End Percentage of Buffer B</InputLabel>
        <FloatEditor
          type=""
          value={action?.BEndPercentage}
          onChange={handleEndPercentChange}
          isRequired
        />
      </Box>
    </>
  );
}

const LIQUID_CONCENTRATION_MAP_PARAM_TYPE =
  'map[github.com/Synthace/antha/stdlib/schemas/aliases.LiquidIdentifier]github.com/Synthace/antha/antha/anthalib/wunit.Concentration';

/**
 * Look through workflow for any map parameter of LiquidIdentifier to Concentration.
 */
function useLiquidConcentration(liquidName: string | undefined): string | undefined {
  return useWorkflowBuilderSelector(state => {
    if (!liquidName) {
      return;
    }
    for (const instance of state.elementInstances) {
      for (const param of instance.element.inputs) {
        if (param.type === LIQUID_CONCENTRATION_MAP_PARAM_TYPE) {
          const liquidConcentration =
            state.parameters[instance.name][param.name]?.[liquidName];
          if (liquidConcentration) {
            return liquidConcentration;
          }
        }
      }
    }
    return;
  });
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
}));
